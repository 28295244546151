@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
body{
	font-family: 'Rubik', sans-serif;
}
.header-top{
	background: #000;
    padding: 10px 0px 5px;
    color: #fff;
    display: inline-flex;
    width: 100%;
    position: fixed;
    z-index: 5;
}
.logo-block{
	 display: inline-flex;
}
.header-rtop {
    float: right;
    display: inline-flex;
    margin: 6px;
}
/* .icon{
	padding-left: 25px;
	font-size: 20px;
} */
.banner{
	background: #000;
	padding: 2em 0em 2em;
	color: #fff;
}

.heading-title{
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 100px 0px 0px 0px;
}
.heading-title-left{
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-weight: 400;
    text-align: left;
    /* padding: 100px 0px 0px 0px; */
    font-size: 26px;
}
.heading-title span{
	color: #1BEFFD;
}
.main{
	background-color: #16171D;
}
.seperator{
	border-right: solid 1px #212529;
}
.seperator-bottom{
	border-bottom: solid 1px #1a1d25;
}
.title {
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-size: 24px;
    font-weight: 400;
}
.sub-title{
    color: #B6B6B6;
    font-family: "Rubik", Sans-serif;
    font-weight: 400;
}
.blog-desc{
	background:#1a1d25;
	padding: 22px 8px;
}
.blog-title,
.blog-title a{
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2em;
}
.blog-title a:hover{
	text-decoration: none;
}
.blog-date {
    color: #BCBBBB;
    font-family: "Ruda", Sans-serif;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 0px 0px 0px;
}
.white{
	color:#fff;
}
article{
	border:solid 1px;
	margin-bottom: 20px;
}
article:hover{
	border:solid 1px #1BEFFD;
}
.pagination{
    text-align: right;
    font-family: "Rubik", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    flex-wrap: wrap;
}
.pagination span{
    color: #4a4a44;
    cursor: pointer;
    margin: 5px;
}
.pagination span.active{
	color: #fff;
}
.pagination span:hover{
	color: #1BEFFD;
    text-decoration: none;
}
.blog-description{
	font-size: 14px;
	line-height: 1.5em;
}
.blog-img img{
	max-width: 100%;
    width: 100%;
    max-height: 330px;
}
.home-bottom{
	background: #1a1d25;
	padding: 80px;
}
.home-bottom h2.title{
  	color: #ffffff;
    font-family: "Rubik", Sans-serif;
    font-size: 45px;
    font-weight: 500;
}
.subscribe{
    padding: 0% 20% 0% 20%;
}
.subscribe-frm{
	padding-bottom: 50px;
	padding-top: 25px;
}
.subscribe-frm input[type=text] {
    padding: 10px;
    font-size: 14px;
    float: left;
    width: 70%;
    box-sizing: border-box;
    border-width: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    position: relative;
    height: 38px;
    background-color: rgba(255,255,255,0.14);
    color: #fff;
}
.subscribe-frm button {
    float: left;
    width: 30%;
    padding: 8px;
    background: #fff;
    color: #000;
    font-size: 15px;
    border: 1px solid #ccc;
    border-left: none;
    cursor: pointer;
    outline: none;
    height: 38px;
    font-weight: 400;
}
.subscribe-frm button:hover{
	background: #1BEFFD;
	color: #fff;
	border: 1px solid #1BEFFD;
}
footer{
	background:#101218;
	padding: 50px 0px;
}
.footer-text,.footer-menu-item{
    color: #B3B3B3;
    font-family: "Rubik", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
.footer-menu-item  span{
	display: inline-flex;
	padding-right: 12px;
}
.footer-menu-item  span i{
	font-size: 17px;
}
.blog-details-date{
    font-size: 14px;
}
.blog-details-date i{
    padding-right: 8px;
}
.blog-details-date span{
    padding-left: 8px;
}
.blog-list-title a {
    color: #FDFEFF;
    font-family: "Rubik", Sans-serif;
    font-size: 17px;
    font-weight: 500;
}
.blog-list-title a:hover{
    text-decoration: none;
}
.blog-list-date{
    font-size: 12px;
    color: #adadad;
}
.blog-list{
    margin-bottom: 50px;
}

table td {
    color: white;
    padding-right: 35px;
    font-size: 17px;
}
.brands img {
    cursor: pointer;
    border-radius: 8px;
    height: 80px;
}
.footer-logo img{
    height: 60px;
}
.pointer {
    cursor: pointer;
}
.sandwitch-btn {
    font-size: 32px;
}
.ant-input-group.ant-input-wrapper {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.main-menu {
    font-weight: 500;
    cursor: pointer;
}
.main-menu span {
    margin: 10px;
}
.main-menu span:hover {
    color: #abd2d2;
}
.item.active {
    color: red;
}
button {
    border-radius: revert;
}
a:hover {
    text-decoration: none;
}

@media(min-width: 767px){
    .mob-menu {
        display: none;
    }
}

@media(max-width: 767px){
	.subscribe {
		padding: 0% 0% 0% 0%;
	}
	.footer-social-icons{
		border-top: solid 1px #1a1d25;
		padding-top:  20px;
		margin-top:  20px;
	}
	.seperator {
    	border-right: none;
	}
	.subscribe-frm button,.subscribe-frm input[type=text]{
		width: 100%;
    }

    .title {
        font-size: 17px; 
    }
    .blog-title a{
        font-size: 15px;
    }
    table td {
        padding-right: 5px;
        font-size: 10px;
    }
    .footer-logo img{
        height: 30px;
    }
    .brands {
        margin-top: 30px;
    }
    .header-top {
        padding: 10px 0px 10px;
    }
    .brands button{
        width: 280px;
        margin-bottom: 5px;
    } 
    .heading-title-left{
        font-size: 18px;
    }
    .main-menu {
        display: none;
    }
}

@media(max-width: 991px){
    .title {
        font-size: 19px; 
    }
    .blog-title a{
        font-size: 17px;
    }
    table td {
        padding-right: 7px;
        font-size: 12px;
    }
}
@media(max-width: 1150px){
    .ant-select-dropdown,
    header {
        zoom: .9
    }
    header .main-menu {
        margin-top: .25rem!important;
    }
}
