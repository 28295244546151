
  .menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .menu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    right: 0;
    /* width: 300px; */
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  }
  
  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 1000;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li:not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }
  
  .menu li span {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
    cursor: pointer;
  }
  