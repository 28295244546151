.login-drawer .ant-drawer-content-wrapper {
    height: auto !important;
    max-width: 500px !important;
}
a {
    color: #1890ff !important;
}
.blog-title.white a {
    color: #FFF !important;
}

.blox-next-icon {
    color: #fff;
    font-size: 34px;
    position: absolute;
    z-index: 1;
    top: 35%;
    right: 0;
}
.blox-previous-icon {
    color: #fff;
    font-size: 34px;
    position: absolute;
    z-index: 1;
    top: 35%;
}
.category-img {
    width: 100%;
    max-height: 320px;
}
.view-more {
    color: blue;
    position: absolute;
    right: 20px;
    cursor: pointer;
    font-size: 16px;
}
h1, h2, h3, h4, h5, h6 {
    color: #FFFFFF
}

.error {
    border: 2px red;
    border-style: solid;
}
.mob-menu .menu.active {
    width: 70vw;
}

@media screen and (max-width: 992px) {
    .blox-next-icon,
    .blox-previous-icon {
        display: none;
    }
}

.select-before {
    width: 90px;
  }
  
  .select-after {
    width: 80px;
  }
  
  [data-theme='compact'] .select-before {
    width: 71px;
  }
  
  [data-theme='compact'] .select-after {
    width: 65px;
  }

.ant-input-group-addon {
    width: unset;
    padding-top: 7px;
}
.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85)
}
/* button.gm-ui-hover-effect {
    visibility: hidden;
} */