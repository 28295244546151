@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
body{
	font-family: 'Rubik', sans-serif;
}
.header-top{
	background: #000;
    padding: 10px 0px 5px;
    color: #fff;
    display: inline-flex;
    width: 100%;
    position: fixed;
    z-index: 5;
}
.logo-block{
	 display: inline-flex;
}
.header-rtop {
    float: right;
    display: inline-flex;
    margin: 6px;
}
/* .icon{
	padding-left: 25px;
	font-size: 20px;
} */
.banner{
	background: #000;
	padding: 2em 0em 2em;
	color: #fff;
}

.heading-title{
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 100px 0px 0px 0px;
}
.heading-title-left{
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-weight: 400;
    text-align: left;
    /* padding: 100px 0px 0px 0px; */
    font-size: 26px;
}
.heading-title span{
	color: #1BEFFD;
}
.main{
	background-color: #16171D;
}
.seperator{
	border-right: solid 1px #212529;
}
.seperator-bottom{
	border-bottom: solid 1px #1a1d25;
}
.title {
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-size: 24px;
    font-weight: 400;
}
.sub-title{
    color: #B6B6B6;
    font-family: "Rubik", Sans-serif;
    font-weight: 400;
}
.blog-desc{
	background:#1a1d25;
	padding: 22px 8px;
}
.blog-title,
.blog-title a{
    color: #FFFFFF;
    font-family: "Rubik", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2em;
}
.blog-title a:hover{
	text-decoration: none;
}
.blog-date {
    color: #BCBBBB;
    font-family: "Ruda", Sans-serif;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 0px 0px 0px;
}
.white{
	color:#fff;
}
article{
	border:solid 1px;
	margin-bottom: 20px;
}
article:hover{
	border:solid 1px #1BEFFD;
}
.pagination{
    text-align: right;
    font-family: "Rubik", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    flex-wrap: wrap;
}
.pagination span{
    color: #4a4a44;
    cursor: pointer;
    margin: 5px;
}
.pagination span.active{
	color: #fff;
}
.pagination span:hover{
	color: #1BEFFD;
    text-decoration: none;
}
.blog-description{
	font-size: 14px;
	line-height: 1.5em;
}
.blog-img img{
	max-width: 100%;
    width: 100%;
    max-height: 330px;
}
.home-bottom{
	background: #1a1d25;
	padding: 80px;
}
.home-bottom h2.title{
  	color: #ffffff;
    font-family: "Rubik", Sans-serif;
    font-size: 45px;
    font-weight: 500;
}
.subscribe{
    padding: 0% 20% 0% 20%;
}
.subscribe-frm{
	padding-bottom: 50px;
	padding-top: 25px;
}
.subscribe-frm input[type=text] {
    padding: 10px;
    font-size: 14px;
    float: left;
    width: 70%;
    box-sizing: border-box;
    border-width: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    transition: 0.5s;
    outline: none;
    position: relative;
    height: 38px;
    background-color: rgba(255,255,255,0.14);
    color: #fff;
}
.subscribe-frm button {
    float: left;
    width: 30%;
    padding: 8px;
    background: #fff;
    color: #000;
    font-size: 15px;
    border: 1px solid #ccc;
    border-left: none;
    cursor: pointer;
    outline: none;
    height: 38px;
    font-weight: 400;
}
.subscribe-frm button:hover{
	background: #1BEFFD;
	color: #fff;
	border: 1px solid #1BEFFD;
}
footer{
	background:#101218;
	padding: 50px 0px;
}
.footer-text,.footer-menu-item{
    color: #B3B3B3;
    font-family: "Rubik", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
.footer-menu-item  span{
	display: inline-flex;
	padding-right: 12px;
}
.footer-menu-item  span i{
	font-size: 17px;
}
.blog-details-date{
    font-size: 14px;
}
.blog-details-date i{
    padding-right: 8px;
}
.blog-details-date span{
    padding-left: 8px;
}
.blog-list-title a {
    color: #FDFEFF;
    font-family: "Rubik", Sans-serif;
    font-size: 17px;
    font-weight: 500;
}
.blog-list-title a:hover{
    text-decoration: none;
}
.blog-list-date{
    font-size: 12px;
    color: #adadad;
}
.blog-list{
    margin-bottom: 50px;
}

table td {
    color: white;
    padding-right: 35px;
    font-size: 17px;
}
.brands img {
    cursor: pointer;
    border-radius: 8px;
    height: 80px;
}
.footer-logo img{
    height: 60px;
}
.pointer {
    cursor: pointer;
}
.sandwitch-btn {
    font-size: 32px;
}
.ant-input-group.ant-input-wrapper {
    display: flex!important;
}
.main-menu {
    font-weight: 500;
    cursor: pointer;
}
.main-menu span {
    margin: 10px;
}
.main-menu span:hover {
    color: #abd2d2;
}
.item.active {
    color: red;
}
button {
    border-radius: revert;
}
a:hover {
    text-decoration: none;
}

@media(min-width: 767px){
    .mob-menu {
        display: none;
    }
}

@media(max-width: 767px){
	.subscribe {
		padding: 0% 0% 0% 0%;
	}
	.footer-social-icons{
		border-top: solid 1px #1a1d25;
		padding-top:  20px;
		margin-top:  20px;
	}
	.seperator {
    	border-right: none;
	}
	.subscribe-frm button,.subscribe-frm input[type=text]{
		width: 100%;
    }

    .title {
        font-size: 17px; 
    }
    .blog-title a{
        font-size: 15px;
    }
    table td {
        padding-right: 5px;
        font-size: 10px;
    }
    .footer-logo img{
        height: 30px;
    }
    .brands {
        margin-top: 30px;
    }
    .header-top {
        padding: 10px 0px 10px;
    }
    .brands button{
        width: 280px;
        margin-bottom: 5px;
    } 
    .heading-title-left{
        font-size: 18px;
    }
    .main-menu {
        display: none;
    }
}

@media(max-width: 991px){
    .title {
        font-size: 19px; 
    }
    .blog-title a{
        font-size: 17px;
    }
    table td {
        padding-right: 7px;
        font-size: 12px;
    }
}
@media(max-width: 1150px){
    .ant-select-dropdown,
    header {
        zoom: .9
    }
    header .main-menu {
        margin-top: .25rem!important;
    }
}

.image-gallery-icon {
    color: #fff;
    transition: all .2s ease-out;
    -webkit-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
            filter: drop-shadow(0 2px 2px #1a1a1a); }
    @media (min-width: 768px) {
      .image-gallery-icon:hover {
        color: #337ab7; }
        .image-gallery-icon:hover .image-gallery-svg {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); } }
    .image-gallery-icon:focus {
      outline: 2px solid #337ab7; }
  
  .image-gallery-using-mouse .image-gallery-icon:focus {
    outline: none; }
  
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    bottom: 0;
    padding: 20px; }
    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
      height: 36px;
      width: 36px; }
    @media (max-width: 768px) {
      .image-gallery-fullscreen-button,
      .image-gallery-play-button {
        padding: 15px; }
        .image-gallery-fullscreen-button .image-gallery-svg,
        .image-gallery-play-button .image-gallery-svg {
          height: 24px;
          width: 24px; } }
    @media (max-width: 480px) {
      .image-gallery-fullscreen-button,
      .image-gallery-play-button {
        padding: 10px; }
        .image-gallery-fullscreen-button .image-gallery-svg,
        .image-gallery-play-button .image-gallery-svg {
          height: 16px;
          width: 16px; } }
  
  .image-gallery-fullscreen-button {
    right: 0; }
  
  .image-gallery-play-button {
    left: 0; }
  
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 75px;
      width: 60px; }
    @media (max-width: 768px) {
      .image-gallery-left-nav .image-gallery-svg,
      .image-gallery-right-nav .image-gallery-svg {
        height: 72px;
        width: 36px; } }
    @media (max-width: 480px) {
      .image-gallery-left-nav .image-gallery-svg,
      .image-gallery-right-nav .image-gallery-svg {
        height: 48px;
        width: 24px; } }
    .image-gallery-left-nav[disabled],
    .image-gallery-right-nav[disabled] {
      cursor: disabled;
      opacity: .6;
      pointer-events: none; }
  
  .image-gallery-left-nav {
    left: 0; }
  
  .image-gallery-right-nav {
    right: 0; }
  
  .image-gallery {
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    position: relative; }
    .image-gallery.fullscreen-modal {
      background: #000;
      bottom: 0;
      height: 100%;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 5; }
      .image-gallery.fullscreen-modal .image-gallery-content {
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
  
  .image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0; }
    .image-gallery-content.fullscreen {
      background: #000; }
    .image-gallery-content .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 80px); }
    .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
      max-height: 100vh; }
  
  .image-gallery-slide-wrapper {
    position: relative; }
    .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
      display: inline-block;
      width: calc(100% - 110px); }
      @media (max-width: 768px) {
        .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
          width: calc(100% - 87px); } }
    .image-gallery-slide-wrapper.image-gallery-rtl {
      direction: rtl; }
  
  .image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center; }
  
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    .image-gallery-slide.center {
      position: relative; }
    .image-gallery-slide .image-gallery-image {
      width: 100%;
      object-fit: contain; }
    .image-gallery-slide .image-gallery-description {
      background: rgba(0, 0, 0, 0.4);
      bottom: 70px;
      color: #fff;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal; }
      @media (max-width: 768px) {
        .image-gallery-slide .image-gallery-description {
          bottom: 45px;
          font-size: .8em;
          padding: 8px 15px; } }
  
  .image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4; }
    .image-gallery-bullets .image-gallery-bullets-container {
      margin: 0;
      padding: 0;
      text-align: center; }
    .image-gallery-bullets .image-gallery-bullet {
      -webkit-appearance: none;
              appearance: none;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      box-shadow: 0 1px 0 #1a1a1a;
      cursor: pointer;
      display: inline-block;
      margin: 0 5px;
      outline: none;
      padding: 5px;
      transition: background .2s ease-out; }
      @media (max-width: 768px) {
        .image-gallery-bullets .image-gallery-bullet {
          margin: 0 3px;
          padding: 3px; } }
      @media (max-width: 480px) {
        .image-gallery-bullets .image-gallery-bullet {
          padding: 2.7px; } }
      .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
        background: #337ab7;
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
      .image-gallery-bullets .image-gallery-bullet.active {
        background: #fff; }
  
  .image-gallery-thumbnails-wrapper {
    position: relative; }
    .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
      direction: rtl; }
    .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
      display: inline-block;
      vertical-align: top;
      width: 100px; }
      @media (max-width: 768px) {
        .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
          width: 81px; } }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
        height: 100%;
        width: 100%;
        left: 0;
        padding: 0;
        position: absolute;
        top: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
          display: block;
          margin-right: 0;
          padding: 0; }
          .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
            margin-left: 0;
            margin-top: 2px; }
    .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
      margin: 0 5px; }
      @media (max-width: 768px) {
        .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
          margin: 0 3px; } }
  
  .image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails {
        padding: 3px 0; } }
    .image-gallery-thumbnails .image-gallery-thumbnails-container {
      cursor: pointer;
      text-align: center;
      transition: -webkit-transform .45s ease-out;
      transition: transform .45s ease-out;
      transition: transform .45s ease-out, -webkit-transform .45s ease-out;
      white-space: nowrap; }
  
  .image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border .3s ease-out;
    width: 100px;
    background: transparent;
    padding: 0; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail {
        border: 3px solid transparent;
        width: 81px; } }
    .image-gallery-thumbnail + .image-gallery-thumbnail {
      margin-left: 2px; }
    .image-gallery-thumbnail .image-gallery-thumbnail-inner {
      position: relative; }
    .image-gallery-thumbnail .image-gallery-thumbnail-image {
      vertical-align: middle;
      width: 100%;
      line-height: 0; }
    .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
      outline: none;
      border: 4px solid #337ab7; }
      @media (max-width: 768px) {
        .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
          border: 3px solid #337ab7; } }
  
  .image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: white;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 1px 1px 0 black;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    white-space: normal;
    width: 100%; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail-label {
        font-size: .8em;
        line-height: .8em; } }
  
  .image-gallery-index {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4; }
    @media (max-width: 768px) {
      .image-gallery-index {
        font-size: .8em;
        padding: 5px 10px; } }
  

  .menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .menu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    right: 0;
    /* width: 300px; */
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    transition: opacity 0.4s ease, visibility 0.4s, -webkit-transform 0.4s ease;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s, -webkit-transform 0.4s ease;
  }
  
  .menu.active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    z-index: 1000;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li:not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }
  
  .menu li span {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
    cursor: pointer;
  }
  
.loading-div {
    fill: white;
    height: 64px;
    width: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.login-drawer .ant-drawer-content-wrapper {
    height: auto !important;
    max-width: 500px !important;
}
a {
    color: #1890ff !important;
}
.blog-title.white a {
    color: #FFF !important;
}

.blox-next-icon {
    color: #fff;
    font-size: 34px;
    position: absolute;
    z-index: 1;
    top: 35%;
    right: 0;
}
.blox-previous-icon {
    color: #fff;
    font-size: 34px;
    position: absolute;
    z-index: 1;
    top: 35%;
}
.category-img {
    width: 100%;
    max-height: 320px;
}
.view-more {
    color: blue;
    position: absolute;
    right: 20px;
    cursor: pointer;
    font-size: 16px;
}
h1, h2, h3, h4, h5, h6 {
    color: #FFFFFF
}

.error {
    border: 2px red;
    border-style: solid;
}
.mob-menu .menu.active {
    width: 70vw;
}

@media screen and (max-width: 992px) {
    .blox-next-icon,
    .blox-previous-icon {
        display: none;
    }
}

.select-before {
    width: 90px;
  }
  
  .select-after {
    width: 80px;
  }
  
  [data-theme='compact'] .select-before {
    width: 71px;
  }
  
  [data-theme='compact'] .select-after {
    width: 65px;
  }

.ant-input-group-addon {
    width: unset;
    padding-top: 7px;
}
.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85)
}
/* button.gm-ui-hover-effect {
    visibility: hidden;
} */
